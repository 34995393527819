import styled, { css } from "styled-components";
import { max_md, min_md } from "../utils/mediaQuery";

const BASE_FONT_SIZE = 16;

function getRelativeSize(coefficient) {
  return `${BASE_FONT_SIZE * coefficient}px`;
}

export default styled.div`
  font-size: ${getRelativeSize(1)};
  line-height: ${getRelativeSize(1.5)};

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    font-size: ${getRelativeSize(1.75)};
    line-height: ${getRelativeSize(3)};
  }

  h2 {
    font-size: ${getRelativeSize(1.5)};
    line-height: ${getRelativeSize(2.5)};
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      height: 100%;
      background-color: ${({ theme, inverse }) =>
        inverse ? theme.colors.normalOnMain : theme.colors.main};
      border-radius: 99px;
    }
  }

  h3 {
    font-size: ${getRelativeSize(1.1125)};
    line-height: ${getRelativeSize(2)};
  }

  * + h2 {
    margin-top: ${getRelativeSize(2.75)};
  }

  * + h3 {
    margin-top: ${getRelativeSize(2.5)};
  }

  * + h4 {
    margin-top: ${getRelativeSize(2)};
  }

  * + h5 {
    margin-top: ${getRelativeSize(1.75)};
  }

  * + h6 {
    margin-top: ${getRelativeSize(1.5)};
  }

  * + p,
  * + table,
  * + img,
  * + ul,
  * + ol,
  * + dl,
  * + figure,
  * + iframe {
    margin-top: ${getRelativeSize(1.25)};
  }

  * + li {
    margin-top: ${getRelativeSize(0.75)};
  }

  p {
    font-size: ${getRelativeSize(1)};
    line-height: ${getRelativeSize(1.75)};
  }

  img {
    display: block;
    border-radius: 16px;
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 0 auto;

    &.no-rounded {
      border-radius: 0;
    }
  }

  * + figcaption {
    margin-top: ${getRelativeSize(0.75)};
  }

  figcaption {
    font-size: ${getRelativeSize(0.8)};
    text-align: center;
    color: ${({ theme }) => theme.colors.muted};
  }

  .media-left {
    width: 100%;

    ${min_md(css`
      float: left;
      margin: 20px 1.5em 1.5em 0;
      max-width: 40%;
    `)}
  }

  a {
    word-break: break-all;
  }

  ul,
  ol {
    padding-left: 1.1em;

    p {
      margin: 0;
    }

    li {
      ul,
      ol {
        padding-left: 2rem;
        margin-top: ${getRelativeSize(0.75)};
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-top: 1px solid ${({ theme }) => theme.colors.border};
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};

      ${max_md(css`
        display: flex;
        flex-direction: column;
        margin: 0 0 -1px;
        padding: 16px 0;
      `)}

      td,
      th {
        padding: 16px 0 16px 16px;
        text-align: left;
        vertical-align: top;
        font-size: ${getRelativeSize(1)};
        line-height: ${getRelativeSize(1.75)};

        &:first-child {
          padding-left: 0;
        }

        ${max_md(css`
          padding: 0;

          &:first-child {
            padding-bottom: 8px;
          }
        `)}
      }
    }
  }

  .twitter-tweet:not(.twitter-tweet-rendered) {
    visibility: hidden;
  }

  .twitter-tweet-rendered {
    margin: 0 auto;
  }

  .youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 16px;
  }
`;
