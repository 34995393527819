import React from "react";
import styled from "styled-components";
import LinkButton from "../components/LinkButton";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import CareersBanner from "../components/CareersBanner";
import MarkdownBody from "../components/MarkdownBody";
import { graphql } from "gatsby";

export default ({ data }) => {
  return (
    <>
      <SEO
        description=""
        title={data.markdownRemark.frontmatter.title}
        image={data.markdownRemark.frontmatter.thumbnail || undefined}
      />
      <PageHeader
        button={
          <LinkButton inverse small to="/news/">
            ニュース
          </LinkButton>
        }
        title={data.markdownRemark.frontmatter.title}
        subtitle={data.markdownRemark.frontmatter.subtitle ?? undefined}
        lead={data.markdownRemark.frontmatter.publishedAt}
      />
      <Container>
        {data.markdownRemark.frontmatter.thumbnail && (
          <EyecatchImage
            src={data.markdownRemark.frontmatter.thumbnail}
            alt={data.markdownRemark.frontmatter.title}
          />
        )}
      </Container>
      <Container>
        <MarkdownBody
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </Container>
      <CareersBanner />
    </>
  );
};

export const query = graphql`
  query($collectionType: String!, $slug: String!) {
    markdownRemark(
      frontmatter: {
        collectionType: { eq: $collectionType }
        slug: { eq: $slug }
      }
    ) {
      html
      frontmatter {
        publishedAt(formatString: "YYYY/M/D")
        slug
        thumbnail
        title
        subtitle
      }
    }
  }
`;

const EyecatchImage = styled.img`
  display: block;
  border-radius: 16px;
  width: 100%;
  height: auto;
  margin: 0 0 50px;
`;
