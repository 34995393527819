import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const LinkButton = props => <Button {...props} />;

LinkButton.propTypes = {
  inverse: PropTypes.bool,
  narrow: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  inverse: false,
  narrow: false,
  small: false,
};

const LinkWrapper = ({ inverse, narrow, small, ...props }) => (
  <Link {...props} />
);

const Button = styled(LinkWrapper)`
  display: inline-flex;
  height: 48px;
  padding: 0 32px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  border-radius: 999px;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(14, 65, 118, 0.32);
  ${({ narrow, small }) =>
    !narrow &&
    !small &&
    css`
      min-width: 240px;
    `}
  ${({ small }) =>
    small &&
    css`
      height: 32px;
      padding: 0 16px;
    `}
  ${({ inverse }) =>
    inverse
      ? css`
          background: ${({ theme }) => theme.colors.normalOnMain};
          color: ${({ theme }) => theme.colors.main};
        `
      : css`
          background: ${({ theme }) => theme.colors.main};
          color: ${({ theme }) => theme.colors.normalOnMain};
        `}

  transition: 0.25s ease box-shadow, 0.25s ease transform;
  &:hover,
  &:focus:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px 0 rgba(14, 65, 118, 0.64);
  }
  &:active,
  &:focus:active {
    transform: translateY(0);
    box-shadow: none;
  }
  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.focus};
    outline: 0;
  }

  [data-icon] {
    margin-left: 3px;
  }
`;

export default LinkButton;
