import React from "react";
import styled, { css } from "styled-components";
import Container from "./Container";
import HeaderWave from "./HeaderWave";
import { headerHeight } from "./Header";
import { max_md } from "../utils/mediaQuery";
import { pageHeaderContent } from "../utils/zIndex";

export default ({ button, title, subtitle, lead }) => (
  <PageHeader>
    <PageHeaderWave />
    <Container>
      <Content>
        {button && <ButtonBase>{button}</ButtonBase>}
        <Title hasButton={!!button}>{title}</Title>
        {subtitle && <SubTitle hasButton={!!button}>{subtitle}</SubTitle>}
        <Lead hasButton={!!button}>{lead}</Lead>
      </Content>
    </Container>
  </PageHeader>
);

const PageHeader = styled.div`
  padding: ${headerHeight} 0 0;
  margin: 0 0 130px;
  position: relative;
  background: ${({ theme }) => theme.colors.main};

  ${max_md(css`
    margin: 0 0 80px;
  `)}
`;

const PageHeaderWave = styled(HeaderWave)`
  position: absolute;
  left: 0;
  right: 0;
  /* ウェーブ画像と青背景部分の間にわずかなギャップが表示されないよう、
  100%ではなく99%とした。 */
  top: 99%;
  height: 50px;
  width: 100%;

  ${max_md(css`
    height: 25px;
  `)}
`;

const Content = styled.div`
  padding: 40px 0;
  z-index: ${pageHeaderContent};
  position: relative;
`;

const BaseStyle = css`
  @media (prefers-reduced-motion: no-preference) {
    animation: 0.75s ease appearing-header-content both;
  }

  @keyframes appearing-header-content {
    from {
      opacity: 0;
      transform: translateY(15px);
    }

    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
`;

const ButtonBase = styled.div`
  ${BaseStyle}
  /* 左右方向のネガティブマージンはレイアウト調整 */
  margin: 0 -4px 16px;
`;

const Title = styled.h1`
  ${BaseStyle}
  color: ${({ theme }) => theme.colors.normalOnMain};
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 10px;

  ${({ hasButton }) =>
    hasButton &&
    css`
      font-size: 30px;
      line-height: 1.4;

      ${max_md(css`
        font-size: 24px;
        line-height: 1.6;
      `)}
    `}
`;

const SubTitle = styled.p`
  ${BaseStyle}
  color: ${({ theme }) => theme.colors.mutedOnMain};
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4;
  margin: 0 0 10px;
`;

const Lead = styled.p`
  ${BaseStyle}
  color: ${({ theme }) => theme.colors.normalOnMain};
  margin: 0;
  font-weight: bold;
  font-size: 16px;

  ${({ hasButton }) =>
    hasButton &&
    css`
      font-weight: normal;
    `}
`;
