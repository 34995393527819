import React from "react";
import styled from "styled-components";

export default ({ className }) => (
  <Svg
    viewBox="0 0 1920 87"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className}
  >
    <MainPath d="m1920.11 0v81.49s-295.22-39.28-724.57-39.28-654.35 98.65-1195.54 0v-42.21z" />
    <MainPath d="m1429.37 16.61c-52.53 0-106.14.4-159.82 1.33-253 4.39-468.52 37-746.78 37-150.58-.01-319.59-9.57-522.77-38.31v25.58c180.42 32.89 325.65 43.84 455.07 43.84 258.8 0 454.25-43.84 740.47-43.84 429.35 0 724.57 39.28 724.57 39.28v-54.79c-115.72-4.57-295.9-10.09-490.74-10.09" />
    <OverlayPath d="m1429.37 16.61c-52.53 0-106.14.4-159.82 1.33-253 4.39-468.52 37-746.78 37-150.58-.01-319.59-9.57-522.77-38.31v25.58c180.42 32.89 325.65 43.84 455.07 43.84 258.8 0 454.25-43.84 740.47-43.84 429.35 0 724.57 39.28 724.57 39.28v-54.79c-115.72-4.57-295.9-10.09-490.74-10.09" />
  </Svg>
);

const Svg = styled.svg`
  display: block;
`;

const MainPath = styled.path`
  fill: ${({ theme }) => theme.colors.main};
`;

const OverlayPath = styled.path`
  fill: ${({ theme }) => theme.colors.normalOnMain};
  fill-opacity: 0.25;
`;
