import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "./LinkButton";
import { max_md } from "../utils/mediaQuery";

export default () => (
  <Banner>
    <BannerBox>
      <Heading>
        Careers at <HeadingStrong>SocialDog</HeadingStrong>
      </Heading>
      <Lead>私達と一緒に働きませんか</Lead>
      <LinkButton
        inverse
        as="a"
        href="https://portal.socialdog.jp/recruit"
        target="_blank"
        rel="noopener noreferrer"
      >
        採用情報
        <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
      </LinkButton>
    </BannerBox>
  </Banner>
);

const Banner = styled.div`
  background: ${({ theme }) => theme.colors.main};
  margin: 140px 0 0;
  padding: 64px 15px;

  ${max_md(css`
    padding: 30px 15px;
    margin: 80px 0 0;
  `)}
`;

const BannerBox = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.normalOnMain};
  font-size: 40px;
  line-height: 1.2;
  margin: 0 0 10px;
  font-weight: normal;

  ${max_md(css`
    font-size: 20px;
  `)}
`;

const HeadingStrong = styled.strong`
  font-weight: bold;
`;

const Lead = styled.p`
  color: ${({ theme }) => theme.colors.mutedOnMain};
  margin: 0 0 24px;

  ${max_md(css`
    margin: 0 0 12px;
  `)}
`;
